import {React,useState} from 'react'

import {FaBars,FaTimes,FaGithub,FaLinkedin} from 'react-icons/fa'
import {HiOutlineMail} from 'react-icons/hi'
import {BsFillPersonLinesFill} from 'react-icons/bs'
import Logo from '../assets/Danish Kazi-logos_white.png'


const Navbar = () => {
    const [nav, setnav] = useState(false)
    const handleClick=()=>{
        setnav(!nav)
    }
  return (
    <>
    <div className='fixed w-full flex h-[80px] justify-between items-center px-4 bg-[#0a192f] text-gray-50'>
        <div>
            <img src={Logo} alt="sayed danish kazi logo" style={{width:'80px'}} />
        </div>

        {/* Menu */}
        
            <ul className='hidden md:flex'>
                <li>Home</li>
                <li>About</li>
                <li>Skills</li>
                <li>Contact Me</li>
            </ul>
        
        {/* Hamburger menu */}
        <div onClick={handleClick} className='md:hidden z-10'>
            {!nav ? <FaBars/>:<FaTimes/>}
            
        </div>
        {/* Mobile Menu */}
        <ul className={!nav? 'hidden':'absolute top-0 left-0 w-full h-screen bg-[#0a192f] flex flex-col justify-center items-center'}>
                <li className='py-6 text-4xl'>Home</li>
                <li className='py-6 text-4xl'>About</li>
                <li className='py-6 text-4xl'>Skills</li>
                <li className='py-6 text-4xl'>Contact Me</li>
        </ul>

        <div className='hidden lg:flex fixed flex-col top-[35%] left-0'>
            <ul>
                <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-blue-600'>
                    <a target='_blank' className='flex justify-between items-center w-full text-gray-300' href="https://www.linkedin.com/in/sayed-danish-kazi/">LinkedIn <FaLinkedin size={30}/></a>
                </li>
                <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#333333]'>
                    <a target='_blank' className='flex justify-between items-center w-full text-gray-300' href="https://github.com/danish07-glitch">GitHub <FaGithub size={30}/></a>
                </li>
                <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#6fc2b0]'>
                    <a target='_blank' className='flex justify-between items-center w-full text-gray-300' href="mailto:dkazi6@gmail.com">Email <HiOutlineMail size={30}/></a>
                </li>
                <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#565f69]'>
                    <a target='_blank' className='flex justify-between items-center w-full text-gray-300' href="https://drive.google.com/file/d/1Bnc5Iz4XPPLkrPKXBvVhJHimTwy-y0sg/view?usp=sharing">Resume <BsFillPersonLinesFill size={30}/></a>
                </li>
            </ul>

        </div>
    </div>
    
    </>
    
  )
}

export default Navbar