import About from './components/About';
import Home from './components/Home';
import Navbar from './components/Navbar';
import Skills from './components/Skills';
import logo from './logo.svg';


function App() {
  return (
   <>
   <Navbar/>
   <Home/>
   <About/>
   <Skills/>
   </>
  );
}

export default App;
