import React from 'react'

const About = () => {
  return (
    <div name='about' className='w-full h-screen bg-[#0a192f] text-gray-300'>
        <div className='flex flex-col justify-center items-center w-full h-full'>

            <div className='max-w-[1000px] w-full px-4 grid grid-cols-2 gap-8'>

            <div className='sm:text-right pb-8 pl-4'>
                <p className='text-4xl font-bold inline border-b-4 border-pink-600'>About</p>
            </div>
            <div></div>
                
            </div>
            <div className='max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4'>
                <div>
                    <p className='sm:text-right text-4xl font-bold'>Hi, I'm Danish,nice to meet you.Please take a look around</p>
                </div>
                <div>
                    <p>I'm Passionate about building excellent Websites that improves the lives of those around me.I Specialize in creating websites for clients ranging from individuals and small-bussiness all the way to large enterprise corporations. </p>
                </div>
            </div>


        </div>
    </div>
  )
}

export default About